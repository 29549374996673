var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ax-table',{attrs:{"column-select":false,"footer-props":_vm.footerProps,"headers":_vm.headers,"items":_vm.connections,"options":_vm.tableStateLocal,"server-items-length":_vm.serverConnectionsLength,"item-key":"id"},on:{"update:options":function($event){_vm.tableStateLocal=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":_vm.connectionEditorRoute(item.id)}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-uppercase"},[_vm._v(_vm._s(item.type))])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$d(new Date(item.created_at), 'long'))+" ")]}},{key:"item.provision_type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t( ("setup.authentication.connections.provisionTypes:values." + (item.provision_type)) ))+" ")]}},{key:"item.domains",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.domains.length)+" ")]}},{key:"item.zones",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.provision_type === _vm.ProvisionType.zone_rbac ? item.zones.length : _vm.$t('general.phrases.na'))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('ax-table-row-actions',[_c('v-list-item',{attrs:{"to":_vm.connectionEditorRoute(item.id)}},[_vm._v(" "+_vm._s(_vm.$t('general.buttons.edit'))+" ")]),_c('v-list-item',{on:{"click":function($event){return _vm.modals.testConnection.open({ connection: item })}}},[_vm._v(" "+_vm._s(_vm.$t('general.buttons.test'))+" ")]),_c('v-list-item',{on:{"click":function($event){return _vm.promptDeleteConnection(item)}}},[_vm._v(" "+_vm._s(_vm.$t('general.buttons.remove'))+" ")])],1)]}},{key:"no-data",fn:function(){return [_c('p',{staticClass:"mt-4 content--text"},[_c('strong',[_vm._v(_vm._s(_vm.$t('setup.authentication.connections.noConnections:notification')))]),_c('br'),_c('i18n',{attrs:{"path":"setup.authentication.connections.clickToAddConnectionLink:prompt"}},[_c('router-link',{attrs:{"to":{ name: 'createConnection' }}},[_vm._v(_vm._s(_vm.$t('general.buttons.clickHere')))])],1)],1)]},proxy:true}])}),_c('ax-modal',{model:{value:(_vm.modals.deleteConnection.opened),callback:function ($$v) {_vm.$set(_vm.modals.deleteConnection, "opened", $$v)},expression:"modals.deleteConnection.opened"}},[(_vm.connectionToDelete)?_c('v-card',[_c('v-card-title',[_vm._v(_vm._s(_vm.$t('setup.authentication.connections.removeConnection:phrase')))]),_c('v-card-text',[_vm._v(_vm._s(_vm.$t( 'setup.authentication.connections.confirmRemoveConnection:phrase', { name: _vm.connectionToDelete.name } )))]),_c('v-card-actions',{staticClass:"tw-justify-end tw-px-6 tw-pb-4"},[_c('ax-button',{attrs:{"mode":"secondary"},on:{"click":_vm.cancelDeleteConnection}},[_vm._v(" "+_vm._s(_vm.$t('general.buttons.cancel'))+" ")]),_c('ax-button',{attrs:{"color":"error","mode":"primary"},on:{"click":_vm.deleteConnection}},[_vm._v(" "+_vm._s(_vm.$t('general.buttons.remove'))+" ")])],1)],1):_vm._e()],1),_c('ax-test-connection-modal',{attrs:{"modal-state":_vm.modals.testConnection}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }