import { SetupContext, ref, computed } from '@vue/composition-api';
import { AccountUUID } from '@ax/api-clients-accounts/models';
import { User } from '@ax/data-services-user/models/user';
import { Connection, ConnectionUUID } from '@/models/authentication';
import {
  deleteConnection,
  getConnections,
} from '@/services/auth/connections.service';
import { DataTableState, DataTablePageState } from '@/utils/data-table-state';
import { DEFAULT_TABLE_PAGINATION } from '@/utils/constants';
import { QueryStateParametersConfiguration } from '@/utils/query-state';
import { QueryStateStorageConfig } from '@/utils/storage';
import { USER_GET_PROFILE } from '@/store/actions/user';
import { useRouter } from '@ax/vue-utils/router';

interface ConnectionsQueryState extends DataTablePageState<Connection> {
  search?: string;
}

export function getConnectionQueryStateConfiguration(): QueryStateParametersConfiguration<{}> {
  return {
    limit: { defaultValue: DEFAULT_TABLE_PAGINATION, storeInBrowser: true },
    page: { defaultValue: 1 },
    search: { defaultValue: '' },
    sort: { defaultValue: 'created_at:desc' },
  };
}

export function useConnectionsTable(
  context: SetupContext,
  queryStateParametersConfiguration?: QueryStateParametersConfiguration<
    DataTablePageState<Connection>
  >,
) {
  const router = useRouter();
  const user = computed<User>(
    () => context.root.$store.getters[USER_GET_PROFILE],
  );
  const connections = ref<Connection[]>([]);
  const totalServerConnections = ref<number>();
  const queryStateConfig =
    queryStateParametersConfiguration || getConnectionQueryStateConfiguration();
  const storageConfig: QueryStateStorageConfig = {
    key: 'ax-connections-prefs',
    store: localStorage,
  };

  function getConnectionsList(query: string): Promise<void> {
    const queryParams = new URLSearchParams(query);

    return getConnections(
      user.value.account_id as string, // safe to assume this will be present
      Object.fromEntries(queryParams),
    ).then(({ data, metadata }) => {
      connections.value = data;
      totalServerConnections.value = metadata.total_count;
    });
  }

  const { tableState, queryState, updateQueryState } =
    DataTableState.synchronize<Connection, ConnectionsQueryState>(
      router.currentRoute,
      router,
      queryStateConfig,
      {
        callback: ({ apiQuery }) => getConnectionsList(apiQuery),
        storageConfig,
      },
    );

  async function handleDeleteConnection(
    accountId: AccountUUID,
    connectionId: ConnectionUUID,
  ) {
    try {
      await deleteConnection(accountId, connectionId);
    } finally {
      connections.value = connections.value.filter(
        ({ id }) => id !== connectionId,
      );
    }
  }

  return {
    connections,
    deleteConnection: handleDeleteConnection,
    totalServerConnections,
    tableState,
    queryState,
    updateQueryState,
    storageConfig,
  };
}
